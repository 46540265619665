<template>
  <div class="container">
    <div>
      <div>
        <el-button v-indentify="'sys:rule:view:add'" type="primary" icon="el-icon-plus" @click="handleRule('add')">添加权限</el-button>
      </div>
      <div class="table-container">
        <el-table
            :data="tableData"
            border
            style="width: 100%"
        >
          <el-table-column
              prop="id"
              width="80"
              align="center"
              label="ID">
          </el-table-column>
          <el-table-column
              prop="title"
              label="权限名称">
          </el-table-column>
          <el-table-column
              label="图标"
              align="center"
              width="50">
            <template slot-scope="scope">
              <i :class="scope.row.icon"></i>
            </template>
          </el-table-column>
          <el-table-column
              prop="name"
              label="接口">
          </el-table-column>
          <el-table-column
              prop="url"
              width="100"
              label="URL">
          </el-table-column>
          <el-table-column
              prop="component"
              width="100"
              label="组件名称">
          </el-table-column>
          <el-table-column
              prop="weigh"
              label="权重"
              align="center"
              width="80">
          </el-table-column>
          <el-table-column
              prop="indentify"
              label="权限标识">
          </el-table-column>
          <el-table-column
              width="80"
              align="center"
              label="状态">
            <template slot-scope="scope">
              <StatusTag :state="scope.row.status" :data="statusObject" />
            </template>
          </el-table-column>
          <el-table-column
              label="菜单"
              align="center"
              width="80">
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.ismenu"
                  disabled
                  :active-value="1"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
              label="创建时间"
              width="170">
            <template slot-scope="scope">
              <span>{{ scope.row.createtime | dateFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="300"
              align="center"
              label="操作">
            <template slot-scope="scope">
              <el-button v-indentify="'sys:rule:view:add'" type="primary" class="edit-btn" :underline="false" icon="el-icon-plus" size="mini" @click="handleRule('add', scope.row)"
                         style="margin-right: 10px;">添加
              </el-button>
              <el-button v-indentify="'sys:rule:view:edit'" type="primary" class="edit-btn" :underline="false" icon="el-icon-edit" size="mini" @click="editRule(scope.row.id)"
                         style="margin-right: 10px;">修改
              </el-button>
              <el-button v-indentify="'sys:rule:view:del'" slot="reference" type="danger" class="edit-btn" icon="el-icon-delete" :underline="false" size="mini" @click="deleteRule(scope.row.id)"
                         style="margin-right: 10px;">删除
              </el-button>
              <!--              <el-popconfirm
                              @confirm="deleteRule(scope.row.id)"
                              title="确定要删除此权限吗？"
                            >

                            </el-popconfirm>-->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-drawer
        :title="`${handleFlag === 'add' ? '添加' : '修改'}权限`"
        :visible.sync="showRuleDrawer"
        destroy-on-close
        :wrapperClosable="false"
        @close="resetRuleForm"
        direction="rtl">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="dialog-form">
        <el-form-item label="权限ID" v-if="handleFlag !== 'add'" prop="rule_id">
          <el-input v-model="ruleForm.rule_id" disabled/>
        </el-form-item>
        <el-form-item label="菜单" prop="ismenu">
          <el-radio-group v-model="ruleForm.ismenu">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="父级" prop="pid">
          <el-select v-model="ruleForm.pid" placeholder="请选择父级" style="width: 100%;" collapse-tags>
            <el-option
                v-for="item in ruleData"
                :key="item.label"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="接口" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="icon">
          <el-input v-model="ruleForm.icon">
            <el-button slot="append" icon="el-icon-search" @click="dialogIconVisible = true">搜索图标</el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="权重" prop="weigh">
          <el-input v-model="ruleForm.weigh"></el-input>
        </el-form-item>
        <el-form-item label="组件名称" prop="component">
          <el-input v-model="ruleForm.component"></el-input>
        </el-form-item>
        <el-form-item label="路由URL" prop="url">
          <el-input v-model="ruleForm.url"></el-input>
        </el-form-item>
        <el-form-item label="唯一标识" prop="indentify">
          <el-input v-model="ruleForm.indentify"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-switch
              v-model="ruleForm.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-text="statusObject['normal'].label"
              :inactive-text="statusObject['disable'].label"
              active-value="normal"
              inactive-value="disable"
          >
          </el-switch>
        </el-form-item>
        <el-form-item class="demo-drawer__footer">
          <el-button @click="cancelForm">取 消</el-button>
          <el-button type="primary" @click="confirmAdmin()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
    <el-dialog title="图标库" :visible.sync="dialogIconVisible">
      <IconSelect @setIconName="getIconName" />
    </el-dialog>
  </div>
</template>

<script>
import {
  ruleIndexApi,
  ruleEditApi,
  ruleDelApi,
  ruleDataApi,
  ruleAddApi,
  ruleInfoApi
} from '@/api/auth'
import StatusTag from '@/components/pc/common/StatusTag'
import Mixin from '@/utils/Mixin'
import { _replaceNBSP } from '@/utils/tools'
import IconSelect from '@/components/pc/IconSelect'

export default {
  name: 'PcRuleView',
  mixins: [Mixin],
  components: { StatusTag, IconSelect },
  data () {
    return {
      statusList: [{
        label: '全部',
        value: ''
      }, {
        label: '正常',
        value: 'normal'
      }, {
        label: '禁用',
        value: 'disable'
      }],
      tableData: [],
      ruleData: [],
      orderTable: false,
      orderData: [],
      statusObject: {
        normal: {
          type: '',
          label: '正常'
        },
        disable: {
          type: 'danger',
          label: '禁用'
        }
      },
      showRuleDrawer: false,
      handleFlag: '',
      ruleForm: {
        pid: 0,
        name: '',
        title: '',
        weigh: '',
        ismenu: 0,
        status: 'normal',
        url: '',
        icon: '',
        indentify: '',
        rule_id: '',
        component: ''
      },
      dialogIconVisible: false
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      const data = await ruleIndexApi()
      if (data.code === 1) {
        this.tableData = _replaceNBSP(data.data, 'title')
      }
    },
    async getRuleData () {
      const data = await ruleDataApi()
      if (data.code === 1) {
        this.ruleData = _replaceNBSP(data.data, 'label')
      }
    },

    confirmAdmin () {
      const { handleFlag } = this
      if (handleFlag === 'add') {
        this.ruleAdd()
      } else {
        this.editRuleForm()
      }
    },
    cancelForm () {
      this.showRuleDrawer = false
    },
    resetRuleForm () {
      this.$refs.ruleForm.resetFields()
      this.ruleForm = {
        pid: 0,
        name: '',
        title: '',
        weigh: '',
        ismenu: 0,
        status: 'normal',
        url: '',
        icon: '',
        indentify: '',
        rule_id: '',
        component: ''
      }
    },
    getIconName (iconName) {
      this.ruleForm.icon = iconName
      this.dialogIconVisible = false
    },
    async ruleAdd () {
      const data = await ruleAddApi(this.ruleForm)
      if (data.code === 1) {
        this.$message.success('添加成功')
        this.cancelForm()
        await this.getList()
      }
    },
    /**
     * 操作权限中转
     * @param {String} flag 当前操作的类型
     * @param {Object} row 当前行数据
     */
    handleRule (flag, row = '') {
      this.getRuleData()
      this.showRuleDrawer = true
      this.handleFlag = flag
      if (row) {
        const { id, indentify } = row
        this.ruleForm.pid = id
        this.ruleForm.indentify = indentify + ':'
      }
    },
    /**
     * 修改权限数据处理
     * @param {Object} rule_id id
     * @returns {Promise<void>}
     */
    async editRule (rule_id) {
      const data = await ruleInfoApi({ rule_id })
      this.handleRule('edit')
      if (data.code === 1) {
        const { id, pid, name, title, status, weigh, ismenu, url, icon, indentify, component } = data.data
        this.ruleForm = {
          pid,
          rule_id: id,
          name,
          title,
          weigh,
          ismenu,
          status,
          url,
          icon,
          component,
          indentify
        }
      }
    },
    /**
     * 修改权限
     * @returns {Promise<void>}
     */
    async editRuleForm () {
      const data = await ruleEditApi(this.ruleForm)
      if (data.code === 1) {
        this.$message.success('修改成功')
        this.cancelForm()
        await this.getList()
      }
    },
    /**
     * 删除权限
     * @param {Number} rule_id 权限ID
     * @returns {Promise<void>}
     */
    async deleteRule (rule_id) {
      try {
        await this.$confirm('确定要删除此权限吗？')
        const data = await ruleDelApi({ rule_id })
        if (data.code === 1) {
          this.$message.success('删除成功')
          await this.getList()
        }
      } catch (e) {

      }
    },
    handleSizeChange () {
    },
    handleCurrentChange (val) {
      this.page = val
      this.getList()
    }
  }
}
</script>

<style scoped lang="scss">
</style>
