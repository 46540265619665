import { HttpPost } from '@/utils/request'

/**
 * 管理员列表接口
 * @param jsonData
 * @returns {Promise<*>}
 */
export async function adminIndexApi (jsonData) {
  return await HttpPost('/auth.admin/index', jsonData)
}

/**
 * 管理员组列表接口
 * @param jsonData
 * @returns {Promise<*>}
 */
export async function groupIndexApi (jsonData) {
  return await HttpPost('/auth.group/index', jsonData)
}

/**
 * 新增修改时可选管理员组接口
 * @param jsonData
 * @returns {Promise<*>}
 */
export async function adminGroupDataApi (jsonData) {
  return await HttpPost('/auth.admin/groupData', jsonData)
}

/**
 * 新增管理员接口
 * @param jsonData
 * @returns {Promise<*>}
 */
export async function adminAddApi (jsonData) {
  return await HttpPost('/auth.admin/add', jsonData)
}

/**
 * 修改管理员信息接口
 * @param jsonData
 * @returns {Promise<*>}
 */
export async function adminEditApi (jsonData) {
  return await HttpPost('/auth.admin/edit', jsonData)
}

/**
 * 删除管理员接口
 * @param jsonData
 * @returns {Promise<*>}
 */
export async function adminDelApi (jsonData) {
  return await HttpPost('/auth.admin/del', jsonData)
}

/**
 * 读取角色权限树接口
 * @param jsonData
 * @returns {Promise<*>}
 */
export async function groupRoletreeApi (jsonData) {
  return await HttpPost('/auth.group/roletree', jsonData)
}

/**
 * 新增管理员组接口
 * @param jsonData
 * @returns {Promise<*>}
 */
export async function groupAddApi (jsonData) {
  return await HttpPost('/auth.group/add', jsonData)
}

/**
 * 删除管理员组接口
 * @param jsonData
 * @returns {Promise<*>}
 */
export async function groupDelApi (jsonData) {
  return await HttpPost('/auth.group/del', jsonData)
}

/**
 * 修改管理员组接口
 * @param jsonData
 * @returns {Promise<*>}
 */
export async function groupEditApi (jsonData) {
  return await HttpPost('/auth.group/edit', jsonData)
}

/**
 * 管理员组信息接口
 * @param jsonData
 * @returns {Promise<*>}
 */
export async function groupInfoApi (jsonData) {
  return await HttpPost('/auth.group/info', jsonData)
}

/**
 * 权限列表接口
 * @returns {Promise<*>}
 */
export async function ruleIndexApi () {
  return await HttpPost('/auth.rule/index')
}

/**
 * 新增修改时可选权限信息接口
 * @returns {Promise<*>}
 */
export async function ruleDataApi () {
  return await HttpPost('/auth.rule/ruleData')
}

/**
 * 添加权限
 * @param jsonDate
 * @returns {Promise<*>}
 */
export async function ruleAddApi (jsonDate) {
  return await HttpPost('/auth.rule/add', jsonDate)
}

/**
 * 删除权限
 * @param jsonDate
 * @returns {Promise<*>}
 */
export async function ruleDelApi (jsonDate) {
  return await HttpPost('/auth.rule/del', jsonDate)
}

/**
 * 修改权限
 * @param jsonDate
 * @returns {Promise<*>}
 */
export async function ruleEditApi (jsonDate) {
  return await HttpPost('/auth.rule/edit', jsonDate)
}

/**
 * 权限信息接口
 * @param jsonData
 * @returns {Promise<*>}
 */
export async function ruleInfoApi (jsonData) {
  return await HttpPost('/auth.rule/info', jsonData)
}
