<template>
  <div class="icon-select">
    <div class="form">
      <el-input v-model="iconValue" placeholder="请输入icon类名" class="icon-input" @keyup.enter.native="onSearch" @input="onSearch"></el-input>
    </div>
    <div class="icon-list">
      <el-row :gutter="22">
        <el-col :span="2" v-for="item in choiceIconList" :key="item">
          <el-card shadow="hover" class="icon-item" @click.native="choiceIcon(item)">
            <i :class="item" class="icon"></i>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'PcIconSelectComponents',
  data () {
    return {
      iconValue: '',
      choiceIconList: []
    }
  },
  computed: {
    ...mapState('config', ['iconList'])
  },
  created () {
    this.onSearch()
  },
  methods: {
    /**
     * 过滤搜索关键词
     * @param {String} selectValue 搜索关键词
     * @returns {*}
     */
    filterIcon (selectValue) {
      const RegExpContent = new RegExp(selectValue, 'g')
      if (selectValue) {
        const searchIconList = this.iconList.filter(item => item.search(RegExpContent) > -1)
        this.choiceIconList = searchIconList.length
        return searchIconList
      } else {
        return this.iconList
      }
    },
    onSearch () {
      this.choiceIconList = this.filterIcon(this.iconValue)
    },
    choiceIcon (iconName) {
      this.$emit('setIconName', iconName)
    }
  }
}
</script>

<style scoped lang="scss">
$gutter: 20px;
.form {
  display: flex;

  .icon-input {
    flex: 1;
  }
}

.icon-list {
  .icon-item {
    margin-top: $gutter;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      font-size: 20px;
    }
  }
}
.pagination {
  margin-top: 40px;
}
</style>
